<template flex>
  <div class="login d-flex justify-center">
    <v-main class="d-flex ">
      <v-layout fluid flex flex-wrap justify-center class="d-flex">
        <v-flex d-flex xs12 sm5 align-center justify-center elevation-0 class="white">
          <v-card flat class="v-login-box rounded-0" width="100%">
            <div v-if="showCurrPass" class="d-flex mb-16 pb-3">
              <a href="/settings" class="text-decoration-none black--text"
                ><v-icon small left> arrow_back </v-icon> {{ $t('changePass.backSett') }}</a
              >
            </div>
            <v-layout class="flex-column">
              <h1 class="font-weight-medium mb-2 black-text">{{ $t('changePass.chngPass') }}</h1>
            </v-layout>
            <v-card-text class="py-4 px-0">
              <p v-if="true">
                <strong>{{ msg }}</strong>
              </p>
              <v-form v-model="valid" ref="cpform" :action="action" method="POST" role="form">
                <input type="hidden" name="csrf_token" :value="csrf_token">
                <input type="hidden" name="traits.email" :value="email">
                <label class="black--text mb-2 d-flex">{{ $t('changePass.newPass') }}</label>
                <v-text-field
                  label="Enter new password"
                  v-model="newPassword"
                  :append-icon="newToggle ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  :type="newToggle ? 'text': 'password'"
                  @click:append="newToggle = !newToggle"
                  :maxlength="30"
                  :minlength="8"
                  :rules="passwordRules"
                  required
                  outlined
                  dense
                  name="password"
                  @keyup="checkMatch"
                ></v-text-field>

                <label class="black--text mb-2 d-flex">{{ $t('changePass.confirmPass') }}</label>
                <v-text-field
                  label="Confirm new password"
                  v-model="confirmPassword"
                  :append-icon="confirmToggle ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  :type="confirmToggle ? 'text': 'password'"
                  @click:append="confirmToggle = !confirmToggle"
                  :maxlength="30"
                  :minlength="8"
                  :rules="confirmRules"
                  required
                  outlined
                  dense
                ></v-text-field>

                <v-layout justify-end flex-wrap>
                  <v-btn type='submit'
                    block
                    depressed large
                    class="primary white--text mb-7"
                    :disabled="!valid"
                    value="password"
                    name="method"
                  >{{ $t('changePass.submit') }}</v-btn>
                </v-layout>

              </v-form>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex
          d-flex
          xs12
          sm7
          align-center
          justify-center
          elevation-0
          blue
          accent-4
          class="v-login-right"
        >
          <v-card flat class="v-login-right-inner">
            <!-- <div class="pt-4 white--text">
              <h4 class="font-weight-medium pb-6">
                {{ $t('changePass.h4') }}
              </h4>
              <p class="white-text">
                {{ $t('changePass.para') }}
              </p>
            </div> -->
            <v-card-text class="pt-4 px-0 white--text">
              <v-img src="../assets/images/login-img.svg"></v-img>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <div class="text-center">
        <v-dialog
          v-model="msgModel"
          width="300"
          persistent
        >
          <v-card class="d-flex align-center justify-center flex-column">
            <v-card-title  class="d-flex justify-space-between align-center w-100 primary white--text px-6 py-3">
                       <h5>      {{ msgTitle }}</h5>
         
            </v-card-title>

             <v-card-text class="d-flex align-start px-6 py-6">
         
          
          <v-card-title class="text-subtitle-1 pa-0">
            {{ message }}
          </v-card-title>

         
        </v-card-text>


            <v-card-actions class="d-flex justify-end align-center w-100 pb-4 pt-0 px-6">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                
                @click="goToDashboard"
              >
                {{ $t('changePass.ok') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-main>
  </div>
</template>

<script >
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-nocheck
import Vue from 'vue';
import { LSS } from '@core/services';
import { FlowService } from '@services/flow.service';
import { API_ORY_URL } from '@config'
import { UPDATED_INVITE, UPDATED_PWDVER } from 'src/utils/apollo-queries';
import { LoginService } from '@services/login.service';
import { decodeToken } from '@shared/utils/commom.util'

export default Vue.extend({
  name: 'ChangePassword',
  components: {
  },

  data() {
    return {
      newPassword: null,
      confirmPassword: null,
      currPassword: null,
      newToggle: false,
      confirmToggle: false,
      currToggle: false,
      valid:null,
      flow:null,
      action:null,
      csrf_token : null,
      showmsg: false,
      dialog:false,
      msg:"",
      success: false,
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 8) || 'Must be atleast 8 characters',
        v => /[A-Z]/.test(v) || 'Must contain atleast 1 capital letter',
        v => /\d/.test(v) || 'Must contain atleast 1 number',
        //v => /[0-9]/.test(v) || 'Must contain atleast 1 number',//can check for numbers using this too
        //v => (/[$|~=[\]'_+@.,-]{2}/.test(v)) || 'Must contain 2 special characters from the list in regex',
        // v => /\w+/.test(v) || 'Must contain special characters',//not working for some reason
        v => /[^A-Za-z 0-9]/.test(v) || 'Must contain special characters',// by checking presence of any  character other than numbers and aplphabets
        v => !(/\s/.test(v)) || 'Cannot contain spaces',
      ],
      currRules: [
        v => !!v || 'Current Password is required',
        v => (v && v.length >= 6) || 'Password must be atleast 6 characters',
      ],
      confirmRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 9) || 'Must be atleast 9 characters',
        v => /[A-Z]/.test(v) || 'Must contain atleast 1 capital letter',
        v => /\d/.test(v) || 'Must contain atleast 1 number',
        //v => /[0-9]/.test(v) || 'Must contain atleast 1 number',//can check for numbers using this too
        //v => (/[$|~=[\]'_+@.,-]{2}/.test(v)) || 'Must contain 2 special characters from the list in regex',
        // v => /\w+/.test(v) || 'Must contain special characters',//not working for some reason
        v => /[^A-Za-z 0-9]/.test(v) || 'Must contain special characters',// by checking presence of any  character other than numbers and aplphabets
        v => !(/\s/.test(v)) || 'Cannot contain spaces',
        v => (v && v==this.newPassword) || 'Passwords do not match'
      ],
      loading: false,
      msgModel: false,
      message :'',
      msgTitle: '',
      showCurrPass: false,
      fullscreenLoader : null,
      uid: null,
      email: '',
    };
  },
  methods: {
    checkMatch: function(e){
      this.$refs.cpform.validate();
    },
    getRecoveryFlowStatus: async function (){
      this.msg = '';
      this.flow = this.$route.query.flow;
      await FlowService.fetchFlow(this.flow, 'settings')
      .then(async (flowResp) => {
        if(flowResp.error){
          if(flowResp.error.code === 410){
            this.msg='Session expired'
            this.showmsg = true;
            window.location.href = API_ORY_URL + 'self-service/settings/browser'
          }
          if(flowResp.error.code === 401){
            this.msg='Session invalid'
            this.showmsg = true;
            return
          }
        }
        this.action = flowResp.ui.action;
        this.csrf_token = flowResp.ui.nodes[0].attributes.value
        this.uid = flowResp.identity.id
        this.email = flowResp.identity.traits.email
        await this.isUserDisabled();
        // console.log(flowResp)
        if(flowResp.state == 'show_form'){
          this.showmsg = true;
          if(flowResp.ui.messages && flowResp.ui.messages[0] && flowResp.ui.messages[0].id == 1060001){
            this.msg= 'Please create a secure password with in the next 15 minutes.';
            // this.msg = flowResp.ui.messages[0].text;
          }
          if (flowResp?.ui?.nodes && flowResp?.ui?.nodes[8]) {
            if (flowResp?.ui?.nodes[8]?.messages && flowResp?.ui?.nodes[8]?.messages[0] && flowResp?.ui?.nodes[8]?.messages[0].id == 4000005) {
              // this.msg= "Weak password !!! Please try a unique combination with atleast 9 characters including 1 capital letter, 1 numeric and 2 special character"
              this.msg = flowResp?.ui?.nodes[8]?.messages[0].text
            }
          }
        }
        else if(flowResp.state == 'success'){
          if(flowResp.ui.messages && flowResp.ui.messages[0] && flowResp.ui.messages[0].id == 1050001){
            //flowResp.ui.messages[0].text
            this.msg='Password Set Successfully!!!'
          }
          this.showmsg = true;
          //this.msg='Link already used';
          this.msgModel = true;
          this.msgTitle = 'Success!!!'
          this.message = 'Password set successfully'
        }
        else{
          this.showmsg = true;
          this.msg= 'Something seems wrong. Try later';
        }
      })
      .catch((err) => {
        // console.log(err)
        this.showmsg = true;
        this.msg= 'Something seems wrong. Try later.';
      });
    },
    async goToDashboard() {
      this.msgModel = false;
      if(LSS.token && LSS.sessId){
        this.fullscreenLoader = this.$loading.show();
        await this.getUserDetails(decodeToken(LSS.token).UserId).catch(e => {
          this.fullscreenLoader.hide();
          window.location.href = Vue.prototype.$out_url
        })
        this.fullscreenLoader.hide();
        return this.$router.push('/overview');
      }else{
        this.fullscreenLoader = this.$loading.show();
        const pv = await LoginService.pwdVer({
          UserID: this.uid || '0000',
          token: ''
        }).catch(e => {
          this.fullscreenLoader.hide();
        });
        this.fullscreenLoader.hide();
        window.location.href = Vue.prototype.$out_url
      }
    },
    async getUserDetails(UserID) {
      const a = await this.$apollo
        .query({
          query: UPDATED_PWDVER,
          variables: {
            at : Date.now()
          }
      })
      // console.log('debugonly-----getUserDetails-changepassword.vue')
      const token = await LoginService.getUserDetails({
        UserID: UserID,
        SessId: null
      });
      const user = decodeToken(token);
      LSS.token = token
      this.$root.$emit('userNameFetched', user);
      return true;
    },
    updateInvited(){
      this.$apollo
      .query({
        query: UPDATED_INVITE,
        variables: {},
      })
      .then((res) => {
        //console.log(res.data.allUsers)
      })
      .catch((error) => {
        //
      });
    },

    async isUserDisabled(){
      // console.log("inside is user disabled " + this.email );
      if(this.email){
        const status = await LoginService.isUserDisabled(this.email);
        if(status && status.successMessage){
          alert('Your account is either disabled or unauthorized. Try login again or contact Admin')
          LSS.clear();
          window.location.href = Vue.prototype.$out_url
        }
      }
    }
  },
  beforeMount(){
     this.$root.$emit('signInOpen');
    // this.getRecoveryFlowStatus()
   this.getRecoveryFlowStatus()
  },
  mounted() {

    if(LSS.token && LSS.sessId){
      this.showCurrPass = true;
    }else{
      this.showCurrPass = false;
      this.currPassword = 'ert345yu56'
    }
   // this.isUserDisabled();
  }
});
</script>

<style scoped>

  @media only screen and (max-width:601px){
 .v-application .v-main  {
    padding-left: 0px !important;


}
 }
</style>
