var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login d-flex justify-center" },
    [
      _c(
        "v-main",
        { staticClass: "d-flex " },
        [
          _c(
            "v-layout",
            {
              staticClass: "d-flex",
              attrs: {
                fluid: "",
                flex: "",
                "flex-wrap": "",
                "justify-center": "",
              },
            },
            [
              _c(
                "v-flex",
                {
                  staticClass: "white",
                  attrs: {
                    "d-flex": "",
                    xs12: "",
                    sm5: "",
                    "align-center": "",
                    "justify-center": "",
                    "elevation-0": "",
                  },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "v-login-box rounded-0",
                      attrs: { flat: "", width: "100%" },
                    },
                    [
                      _vm.showCurrPass
                        ? _c("div", { staticClass: "d-flex mb-16 pb-3" }, [
                            _c(
                              "a",
                              {
                                staticClass: "text-decoration-none black--text",
                                attrs: { href: "/settings" },
                              },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { small: "", left: "" } },
                                  [_vm._v(" arrow_back ")]
                                ),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("changePass.backSett"))
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _c("v-layout", { staticClass: "flex-column" }, [
                        _c(
                          "h1",
                          { staticClass: "font-weight-medium mb-2 black-text" },
                          [_vm._v(_vm._s(_vm.$t("changePass.chngPass")))]
                        ),
                      ]),
                      _c(
                        "v-card-text",
                        { staticClass: "py-4 px-0" },
                        [
                          true
                            ? _c("p", [_c("strong", [_vm._v(_vm._s(_vm.msg))])])
                            : _vm._e(),
                          _c(
                            "v-form",
                            {
                              ref: "cpform",
                              attrs: {
                                action: _vm.action,
                                method: "POST",
                                role: "form",
                              },
                              model: {
                                value: _vm.valid,
                                callback: function ($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid",
                              },
                            },
                            [
                              _c("input", {
                                attrs: { type: "hidden", name: "csrf_token" },
                                domProps: { value: _vm.csrf_token },
                              }),
                              _c("input", {
                                attrs: { type: "hidden", name: "traits.email" },
                                domProps: { value: _vm.email },
                              }),
                              _c(
                                "label",
                                { staticClass: "black--text mb-2 d-flex" },
                                [_vm._v(_vm._s(_vm.$t("changePass.newPass")))]
                              ),
                              _c("v-text-field", {
                                attrs: {
                                  label: "Enter new password",
                                  "append-icon": _vm.newToggle
                                    ? "mdi-eye-outline"
                                    : "mdi-eye-off-outline",
                                  type: _vm.newToggle ? "text" : "password",
                                  maxlength: 30,
                                  minlength: 8,
                                  rules: _vm.passwordRules,
                                  required: "",
                                  outlined: "",
                                  dense: "",
                                  name: "password",
                                },
                                on: {
                                  "click:append": function ($event) {
                                    _vm.newToggle = !_vm.newToggle
                                  },
                                  keyup: _vm.checkMatch,
                                },
                                model: {
                                  value: _vm.newPassword,
                                  callback: function ($$v) {
                                    _vm.newPassword = $$v
                                  },
                                  expression: "newPassword",
                                },
                              }),
                              _c(
                                "label",
                                { staticClass: "black--text mb-2 d-flex" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("changePass.confirmPass"))
                                  ),
                                ]
                              ),
                              _c("v-text-field", {
                                attrs: {
                                  label: "Confirm new password",
                                  "append-icon": _vm.confirmToggle
                                    ? "mdi-eye-outline"
                                    : "mdi-eye-off-outline",
                                  type: _vm.confirmToggle ? "text" : "password",
                                  maxlength: 30,
                                  minlength: 8,
                                  rules: _vm.confirmRules,
                                  required: "",
                                  outlined: "",
                                  dense: "",
                                },
                                on: {
                                  "click:append": function ($event) {
                                    _vm.confirmToggle = !_vm.confirmToggle
                                  },
                                },
                                model: {
                                  value: _vm.confirmPassword,
                                  callback: function ($$v) {
                                    _vm.confirmPassword = $$v
                                  },
                                  expression: "confirmPassword",
                                },
                              }),
                              _c(
                                "v-layout",
                                {
                                  attrs: { "justify-end": "", "flex-wrap": "" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "primary white--text mb-7",
                                      attrs: {
                                        type: "submit",
                                        block: "",
                                        depressed: "",
                                        large: "",
                                        disabled: !_vm.valid,
                                        value: "password",
                                        name: "method",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("changePass.submit"))
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  staticClass: "v-login-right",
                  attrs: {
                    "d-flex": "",
                    xs12: "",
                    sm7: "",
                    "align-center": "",
                    "justify-center": "",
                    "elevation-0": "",
                    blue: "",
                    "accent-4": "",
                  },
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "v-login-right-inner", attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "pt-4 px-0 white--text" },
                        [
                          _c("v-img", {
                            attrs: {
                              src: require("../assets/images/login-img.svg"),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "v-dialog",
                {
                  attrs: { width: "300", persistent: "" },
                  model: {
                    value: _vm.msgModel,
                    callback: function ($$v) {
                      _vm.msgModel = $$v
                    },
                    expression: "msgModel",
                  },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass:
                        "d-flex align-center justify-center flex-column",
                    },
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass:
                            "d-flex justify-space-between align-center w-100 primary white--text px-6 py-3",
                        },
                        [_c("h5", [_vm._v(" " + _vm._s(_vm.msgTitle))])]
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "d-flex align-start px-6 py-6" },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "text-subtitle-1 pa-0" },
                            [_vm._v(" " + _vm._s(_vm.message) + " ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        {
                          staticClass:
                            "d-flex justify-end align-center w-100 pb-4 pt-0 px-6",
                        },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.goToDashboard },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("changePass.ok")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }